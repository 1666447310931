import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ownerData: {
    firstName: '',
    middleName: '',
    lastName: '',
    sex: '',
    dateOfBirth: '',
    phoneNumber: '',
    email: '',
    password: '',
    profileImage: '',
    address: {
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    },
  },
  submissionStatus: null,
};

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setOwnerData: (state, action) => {
      state.ownerData = { ...state.ownerData, ...action.payload };
    },
    setAddressData: (state, action) => {
      state.ownerData.address = { ...state.ownerData.address, ...action.payload };
    },
    submitSignupSuccess: (state) => {
      state.submissionStatus = 'success';
    },
    submitSignupFailure: (state) => {
      state.submissionStatus = 'failure';
    },
    resetSubmissionStatus: (state) => {
      state.submissionStatus = null;
    },
    submitSignupRequest: (state, action) => {
      // Action handled by saga, no state update needed here
    },
  },
});

export const {
  setOwnerData,
  setAddressData,
  submitSignupSuccess,
  submitSignupFailure,
  resetSubmissionStatus,
  submitSignupRequest,
} = signupSlice.actions;

export default signupSlice.reducer;
