import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ownerData: {
    firstName: '',
    middleName: '',
    lastName: '',
    sex: '',
    dateOfBirth: '',
    phoneNumber: '',
    email: '',
    password: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
    },
  },
  submissionStatus: null, 
};

export const superAdminSignUpSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setOwnerData: (state, action) => {
      state.ownerData = { ...state.ownerData, ...action.payload };
    },
    setAddressData: (state, action) => {
      state.ownerData.address = { ...state.ownerData.address, ...action.payload };
    },
    submitSignupSuccess: (state, action) => {
      state.submissionStatus = 'success';
      state.ownerData = action.payload; 
    },
    submitSignupFailure: (state) => {
      state.submissionStatus = 'failure';
    },
  },
});

export const {
  setOwnerData,
  setAddressData,
  submitSignupSuccess,
  submitSignupFailure,
} = superAdminSignUpSlice.actions;

export const submitSignupRequest = (ownerData, callback) => ({
  type: 'signup/submitSignupRequest',
  payload: ownerData,
  callback, 
});

export const resetSubmissionStatus = () => ({
  type: 'signup/resetSubmissionStatus',
});

export default superAdminSignUpSlice.reducer;
