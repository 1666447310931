import React, { useState } from 'react';

const ProfileCard = ({ patient ,isSearchVisible }) => {
const [selectedPatient, setSelectedPatient] = useState(null);

 // Example patient data
 const patientData = {
  fullName: patient.firstName  + ' ' + patient.middleName + ' ' +patient.lastName,
  phoneNumber: patient.phoneNumber,
  id:  patient._id
 
};
const FormatDate = ({ isoDate }) => {
  
  const date = new Date(isoDate);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 since getMonth() returns 0-indexed month
  const day = String(date.getDate()).padStart(2, '0'); // Pad day to ensure two digits

  // Format as YYYY-MM-DD
  const formattedDate = `${year}-${month}-${day}`;

  return <div>{formattedDate}</div>;
};

console.log("patient data to be send", patientData);

  const city = patient.address?.[0]?.city || 'N/A';
const country = patient.address?.[0]?.country || 'N/A';
console.log(patient.address);
  return (
    <div
    className={`w-[35%]  ${isSearchVisible ? 'w-[5%]' : 'w-[35%]  mt-[0px] '
      } h-[200px]  bg-[#FAFAFA] rounded-[15px] shadow-md overflow-hidden border border-blue-300 transition-all duration-300 ease-in-out`}
  >
    <div className="p-2">
      <div className="flex">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <div className="flex-shrink-0 ml-[90px]">
              <div className={`${isSearchVisible ? 'w-[0px] ml-2 ' : 'w-12 ml-[-30px] mt-2 '
                } h-12 w-12 rounded-full bg-gray-300 flex items-center justify-center text-xl font-bold text-gray-700 `}>
                {patient.firstName[0]}
              </div>
            </div>
            <div>
              <div className={`${isSearchVisible ? 'w-[400px] ml-2 ' : 'w-[92px]  ml-4 mt-2 '} font-bold text-sm text-gray-900`}>  {patient?.firstName || ''} {patient?.middleName || ''} {patient?.lastName || ''}</div>
              <div className={`${isSearchVisible ? 'w-[400px] ml-2 ' : 'w-[92px]  ml-4  '} text-gray-600 text-sm `}>{patient.email}</div>
            </div>
          </div>
          <div className="mt-4 flex text-gray-600">
            <div className="text-center">
              <div className={`${isSearchVisible ? ' ml-2 ' : 'ml-8'} text-sm font-bold text-black`}>{patient.past}</div>
              <div className={`${isSearchVisible ? 'ml-2' : 'ml-6'} `}>Past</div>
            </div>
            <div className="border-r-2 border-[#00F0FF] ml-4"></div>
            <div className="text-center ml-[-20px]">
              <div className="text-sm font-bold ml-6 text-gray-900">{patient.upcoming}</div>
              <div className="ml-8">Upcoming</div>
            </div>
          </div>
        </div>

        <div className="mt-4 ml-2">
          <div className="grid grid-cols-2 gap-4 text-sm">
            <div>
              <div className={`${isSearchVisible ? 'w-[400px]  ' : 'w-[500px] ml-6 '
                } text-[#9DA6AE] `}>Gender</div>
              <div className={`${isSearchVisible ? 'w-[400px] ml-2 ' : 'w-[500px] ml-6 '
                } text-[black `}>{patient.sex}</div>
            </div>
            <div>
              <div className={`ml-[-1%] ${isSearchVisible ? 'w-[400px] ml-2 ' : 'w-[500px] ml-12'
                } text-[#9DA6AE] `}>City</div>
              <div className={`ml-[-1%]  ${isSearchVisible ? 'w-[400px] ml-2 ' : 'w-[500px] ml-8 '
                } text-[black `}>{city}</div>
            </div>
            <div>
              <div className={`${isSearchVisible ? 'w-[400px] ml-2 ' : 'w-[500px] ml-6 '
                } text-[#9DA6AE] `}>Address</div>
              <div className={`${isSearchVisible ? 'w-[400px] ml-2 ' : 'w-[500px] ml-6'
                } text-[black `}>{country}</div>
            </div>
            <div>
              <div className={`ml-[-1%] ${isSearchVisible ? 'w-[400px] ml-2 ' : 'w-[500px] ml-8 '
                } text-[#9DA6AE] `}>Registered Date</div>

              <div className={`ml-[-1%]  ${isSearchVisible ? 'w-[400px] ml-2 ' : 'w-[500px] ml-8 '
                } text-[black `}> {new Date(patient.createdAt).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',  // Use 'long' for full month name
                  day: '2-digit'
                })}</div>
               

            </div>
            <div>
              <div className={`${isSearchVisible ? 'w-[400px] ml-2 ' : 'w-[500px] ml-6 '
                } text-[#9DA6AE] `}>Birthday</div>

              <div className={`${isSearchVisible ? 'w-[400px] ml-2 ' : 'w-[500px] ml-6 '
                } text-[black `}>{new Date(patient.dateOfBirth).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',  // Use 'long' for full month name
                  day: '2-digit'
                })}</div>

        
            </div>
            <div>
              <div className={`ml-[-1%] ${isSearchVisible ? 'w-[400px] ml-2 ' : 'w-[500px] ml-8 '
                } text-[#9DA6AE] `}>Phone Number</div>
              <div className={`ml-[-1%] ${isSearchVisible ? 'w-[400px] ml-2 ' : 'w-[500px] ml-8 '
                } text-[black `}>{patient.phoneNumber}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default ProfileCard;
