import React from 'react'; 
import Sidebar from '../../components/superAdminDashBoard/sideBar';
import Widgets from '../../components/superAdminDashBoard/dashboardWidget';
import Activity from '../../components/superAdminDashBoard/mainContent';
import TopBar from '../../components/clinic/TopBar';

const Dashboard = () => {
  return (
    <div className="flex h-screen">

      <Sidebar />


      <div className="flex-1 p-6">
        {/* <div className=''>
          <TopBar />
        </div> */}
         
        <TopBar/>

        <Widgets />

        <Activity />
      </div>
    </div>
  );
};

export default Dashboard;
