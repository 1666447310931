import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import PlusButton from './PlusButton';
import { useDispatch, useSelector } from 'react-redux';
import { server } from "../../../server";
import { fetchUser } from '../../../Redux/actions/user';
import axios from 'axios';

// const patients = [
//   { id: 1, name: 'John Doe', email: 'john@example.com', availability: 'reception', gender: 'Male', city: 'Addis Ababa', address: 'Bole', registeredDate: 'Jan 15th, 2020', birthday: 'Feb 24th, 1997', phoneNumber: '0985859685', past: 10, upcoming: 2 },
//   { id: 2, name: 'Jane Smith', email: 'jane@example.com', availability: 'other', gender: 'Female', city: 'Addis Ababa', address: 'Sar Bet', registeredDate: 'Feb 10th, 2021', birthday: 'Mar 3rd, 1995', phoneNumber: '0985888888', past: 5, upcoming: 3 },
// ];

// Function to get all patients by clinicId
const fetchPatients = async (clinicId) => {
  try {
    const response = await axios.get(`${server}/api/v2/patients/get-all`, {
      params: { clinicId } // Attach clinicId as query parameter
    });
    return response.data; // Return the data from the response
  } catch (error) {
    console.error('Error fetching patients:', error);
    throw error; // Rethrow the error to handle it in the component
  }
};

const SearchPatient = ({ onPlusClick, onEmployeeClick }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [patients, setPatients] = useState([]);
  const [error, setError] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    const loadPatients = async () => {
      if (user && user.clinicId && user.clinicId.length > 0) {
        const clinicId = user.clinicId[0]?.clinic;

        if (clinicId) {
          try {
            const data = await fetchPatients(clinicId);
            setPatients(data);
          } catch (error) {
            setError('Failed to fetch patients');
            console.error('Fetch Error:', error);
          }
        } else {
          setError('Clinic ID is not valid');
        }
      } else {
        setError('No valid clinic ID found');
      }
    };

    if (user && user.clinicId && user.clinicId.length > 0) {
      loadPatients();
    }
  }, [user]);

  const handlePatientClick = (patient) => {
    setSelectedPatient(patient); 
  };





  return (
    <div className="p-4 mt-[-30px]">
      <div className="flex mb-2">
        <div className="flex mt-4">
          <h2 className="ml-2 font-bold">Patient</h2>
          <PlusButton onClick={onPlusClick} />
        </div>
      </div>
      <input
  type="text"
  placeholder="Search Patient"
  className="w-full py-2 mb-2 rounded-lg px-4 text-sm border border-gray-300 sm:py-1 sm:px-2 md:py-2 mb-[md:px-4"
/>

      <div className="space-y-2 w-[210px] ">
  {patients.map((patient) => (
    <div
      key={patient.id}
      className="flex items-center  bg-gray-200 h-6 rounded-md cursor-pointer"

      onClick={() => onEmployeeClick(patient)}
    >
      <span className="bg-gray-300 text-center text-gray-800 w-6 h-6 rounded-full">
        {patient.firstName ? patient.firstName[0] : ''}
      </span>
      {/* Display the patient's full name */}
      <span className="ml-4 text-gray-700 text-sm">
        {`${patient.firstName} ${patient.middleName ? patient.middleName + ' ' : ''}${patient.lastName}`}
      </span>
    </div>
  ))}
</div>

    </div>
  );
};

export default SearchPatient;
