import React from 'react';

const BarChart = () => {
  const data = [
    { label: "fully agree", value: 400 },
    { label: "Some what agree", value: 300 },
    { label: "some what disagree", value: 200 },
    { label: "fully disagree", value: 150 },
    { label: "I don't know", value: 100 },
  ];

  const maxValue = 400;
  const gridValues = [0, 50, 100, 150, 200, 250, 300, 350, 400];

  return (
    <div className="w-full max-w-2xl mx-auto mt-10">
      <h2 className="text-xl font-bold mb-4 text-center mb-6">doctor explained the treatment well</h2>
      <div className="relative">
        <div className="w-full flex flex-col">
          {data.map((item, index) => (
            <div key={index} className="flex items-center mb-2">
              <span className="text-gray-700 w-1/4 text-right pr-4">{item.label}</span>
              <div className="w-3/4 relative">
                <div className="absolute inset-0 flex justify-between">
                  {gridValues.map((value, i) => (
                    <div
                      key={i}
                      className="h-full border-r border-black"
                      style={{ width: `${100 / (gridValues.length - 1)}%` }}
                    ></div>
                  ))}
                </div>
                <div className="relative z-10 bg-teal-500 h-6 rounded-sm shadow-md" style={{ width: `${(item.value / maxValue) * 100}%` }}></div>
              </div>
            </div>
          ))}
        </div>

        <div className="relative mt-4">
          <div className="absolute left-1/4 w-3/4 flex justify-between">
            {gridValues.map((value, index) => (
              <div key={index} className="flex flex-col items-center" style={{ width: `${100 / (gridValues.length - 1)}%` }}>
                <div className="h-6 w-[2px] bg-black"></div> {/* Bold vertical line */}
                <span className="text-gray-700 mt-1">{value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarChart;
