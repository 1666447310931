import React from 'react';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  return (
    <div className="flex h-screen">
    <div className="w-48 py-32 bg-cyan-500">
      <NavLink
        to="/dashboard"
        className={({ isActive }) =>
          isActive ? "block p-4 mb-2 text-center bg-white" : "block p-4 mb-2 text-center bg-cyan-600"
        }
      >
        <span className={({ isActive }) => 
          isActive ? "text-cyan-600 text-sm font-medium" : "text-white text-sm font-medium"
        }>
          Dash Board
        </span>
      </NavLink>
      <NavLink
        to="/clinicManagment"
        className={({ isActive }) =>
          isActive ? "block p-4 mb-2 text-center bg-white" : "block p-4 mb-2 text-center bg-cyan-600"
        }
      >
        <span className={({ isActive }) => 
          isActive ? "text-cyan-600 text-sm font-medium" : "text-white text-sm font-medium"
        }>
          Clinic Management
        </span>
      </NavLink>
      <NavLink
        to="/management"
        className={({ isActive }) =>
          isActive ? "block p-4 mb-2 text-center bg-white" : "block p-4 mb-2 text-center bg-cyan-600"
        }
      >
        <span className={({ isActive }) => 
          isActive ? "text-cyan-600 text-sm font-medium" : "text-white text-sm font-medium"
        }>
          User Management
        </span>
      </NavLink>   
    </div>
  </div>
  );
};

export default Sidebar;
