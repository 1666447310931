import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import Sidebar from './signInSideBar';
import {

  setOwnerData,
  setAddressData,
  submitSignupRequest,
  resetSubmissionStatus,
} from '../../slices/superAdminSignUp';
 // Adjust the path if necessary
import 'react-toastify/dist/ReactToastify.css';

const AdminSignUpForm = () => {
  const dispatch = useDispatch();
  const ownerData = useSelector((state) => state.signup.ownerData);
  const submissionStatus = useSelector((state) => state.signup.submissionStatus);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name in ownerData.address) {
      dispatch(setAddressData({ [name]: value }));
    } else {
      dispatch(setOwnerData({ [name]: value }));
    }
  };

  const handleRegister = () => {
    dispatch(submitSignupRequest(ownerData));
  };
  
  useEffect(() => {
    if (submissionStatus === 'success') {
      // toast.success('Signup successful!');
      dispatch(resetSubmissionStatus());
    } else if (submissionStatus === 'failure') {
      // toast.error('Signup failed. Please try again.');
      dispatch(resetSubmissionStatus());
    }
  }, [submissionStatus, dispatch]);

  return (
    <div className="relative flex h-screen">
      <ToastContainer />

      <Sidebar />

      <div className="flex ml-40">
        <div className="rounded-lg w-full">
          <h1 className="text-2xl font-bold mb-6 mt-[60px]">Sign Up</h1>
          <div className="flex">
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              className="border border-gray-300 p-2 px-6 mr-6 w-[300px] rounded-[15px]"
              value={ownerData.firstName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="middleName"
              placeholder="Middle Name"
              className="border border-gray-300 p-2 px-6 mr-6 w-[300px] rounded-[15px]"
              value={ownerData.middleName}
              onChange={handleInputChange}
            />
          </div>
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            className="border border-gray-300 p-2 px-6 mt-4 w-[300px] rounded-[15px]"
            value={ownerData.lastName}
            onChange={handleInputChange}
          />
          <div>
            <div className="flex">
              <div className="flex flex-col">
                <label className="mt-4 ml-2 font-bold">Date Of Birth</label>
                <input
                  type="text"
                  name="dateOfBirth"
                  placeholder="Month | Day | Year"
                  className="border border-gray-300 p-2 px-6 mt-4 w-[300px] rounded-[15px]"
                  value={ownerData.dateOfBirth}
                  onChange={handleInputChange}
                />
              </div>
              <select
                name="sex"
                className="border border-gray-300 p-2 px-6 h-[41px] mt-[55px] w-[300px] ml-6 rounded-[15px]"
                value={ownerData.sex}
                onChange={handleInputChange}
              >
                <option value="">Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
          </div>
          <div className="flex items-center mt-4">
            <div className="flex-shrink-0">
              <select className="border border-gray-300 p-2 px-6 rounded-[15px]">
                <option value="+251">+251</option>
              </select>
            </div>
            <div>
              <input
                type="text"
                name="phoneNumber"
                placeholder="Phone"
                className="border border-gray-300 p-2 px-6 mr-6 w-[300px] ml-4 rounded-[15px]"
                value={ownerData.phoneNumber}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="flex">
            <input
              type="text"
              name="email"
              placeholder="Email"
              className="border border-gray-300 p-2 px-6 mt-4 w-[300px] rounded-[15px]"
              value={ownerData.email}
              onChange={handleInputChange}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              className="border border-gray-300 p-2 px-6 mr-6 w-[300px] ml-6 mt-4 rounded-[15px]"
              value={ownerData.password}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex items-center mt-6 text-sm">
            <label className="flex items-center">
              <input type="checkbox" className="mr-2" />
              Keep me remembered?
            </label>
            <a href="#" className="ml-[-10px] px-4 text-cyan-500">
              Forgot password?
            </a>
          </div>
          <div className="flex">
            <button
              className="bg-cyan-500 text-black border border-gray-300 p-2 px-6 w-full mt-4 rounded-[15px] text-center text-sm"
              onClick={handleRegister}
            >
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSignUpForm;
