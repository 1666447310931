import React from 'react';
import TopBar from '../../components/reception/topBar';
import Sidebar from '../../components/reception/sideBar';
import Widgets from '../../components/reception/dashboardWidget';
import Activity from '../../components/reception/mainContent';

const ReceptionDashboard = () => {
  return (
    <div className="flex h-screen ">

      <Sidebar />


      <div className="flex-1 p-6">
        {/* <div className=''>
          <TopBar />
        </div> */}



        <Widgets />

        <Activity />
      </div>
    </div>
  );
};

export default ReceptionDashboard;
