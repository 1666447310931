import React from 'react';
import { BellIcon, CogIcon, UserCircleIcon } from '@heroicons/react/outline';
import SearchBarWithFilter from './searchWithFilter';
const TopBar = () => {
  return (
    <div className="flex  mt-[-510px] ml-[20px] items-center space-x-[400px]">
      <div>
        <SearchBarWithFilter />
      </div>
      {/* <div className='flex gap-2 mb-10  ml-[-90px] justify-end'>
        <BellIcon className="h-6 w-6 text-blue-500" />
        <CogIcon className="h-6 w-6 text-blue-500" />
        <div className="flex items-center space-x-2">
          <UserCircleIcon className="h-6 w-6 text-blue-500" />
          <span className="text-blue-500 font-medium">Welcome!</span>
        </div>
      </div> */}

    </div>
  );
};

export default TopBar;
