import { FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE } from '../actions/user';

const initialState = {
  loading: false,
  clinicId: null,
  user: null,
  error: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          id: action.payload.id,
          firstName: action.payload.firstName,
          middleName: action.payload.middleName,
          lastName:action.payload.lastName,
          email: action.payload.email,
          clinicId: action.payload.clinicId,
          role: action.payload.role, // Ensure the role is included
        }
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
