import React from 'react';
import SearchBarWithFilter from './searchWithFilter';
const TopBar = () => {
  return (
    <div className="flex  mt-[-510px] ml-[20px] items-center space-x-[400px]">
      <div>
        <SearchBarWithFilter />
      </div>

    </div>
  );
};

export default TopBar;
