import React, { useState } from 'react';

const tabs = [
  { id: 'previous', label: 'Previous Treatment' },
  { id: 'history', label: 'Payment History' },
  { id: 'upcoming', label: 'Upcoming Payments' },
];

const treatments = [
  { date: "25 Nov '19", time: '09.00 - 10.00', treatment: 'Braces phase 1', dentist: 'Dr Adam H.', nurse: 'Jessica', price: '165$', status: 'Paid' },
  { date: "18 Oct '19", time: '09.00 - 10.00', treatment: 'Root canal prep', dentist: 'Dr Adam H.', nurse: 'Jessica', price: '352$', status: 'Paid' },
];

const ClinicPage = ({ isSearchVisible }) => {
  const [activeTab, setActiveTab] = useState('previous');

  const renderContent = () => {
    switch (activeTab) {
      case 'previous':
        return treatments.map((item, index) => (
          <div key={index} className="flex flex-col md:flex-row bg-[#FCFFFF] rounded-lg my-4 ">
            <div className="flex-1 mb-2">
              <div className="text-sm text-[#263446]">{item.date}</div>
              <div className="text-sm text-[#A6AFB7]">{item.time}</div>
            </div>
            <div className="flex-1 text-[#A6AFB7] mt-[-2px] ml-6">
              <div>Treatment</div>
              <div className="text-[#303D4E] ">{item.treatment}</div>
            </div>
            <div className="flex-1 text-[#A6AFB7] mt-[-2px] ml-6">
              <div>Dentist</div>
              <div className="text-[#303D4E]">{item.dentist}</div>
            </div>
            <div className="flex-1 text-[#A6AFB7] mt-[-2px] ml-6">
              <div>Nurse</div>
              <div className="text-[#303D4E]">{item.nurse}</div>
            </div>
          </div>
        ));
      case 'history':
        return treatments.map((item, index) => (
          <div key={index} className="flex flex-col md:flex-row justify-between bg-[#FCFFFF] rounded-lg my-4 ">
            <div className="flex-1 text-[#A6AFB7]">
              <div className='text-sm'>Paid on</div>
              <div className="text-black">{item.date}</div>
            </div>
            <div className="flex-1 text-[#A6AFB7] mt-[-2px] ml-6">
              <div>Treatment</div>
              <div className="text-black">{item.treatment}</div>
            </div>
            <div className="flex-1 text-[#A6AFB7] mt-[-2px] ml-6">
              <div>Dentist</div>
              <div className="text-black">{item.dentist}</div>
            </div>
            <div className="flex-1 text-[#A6AFB7] mt-[-2px] ml-6">
              <div>Price</div>
              <div className="font-semibold text-green-500">{item.price}</div>
            </div>
          </div>
        ));
      case 'upcoming':
        return treatments.map((item, index) => (
          <div key={index} className="flex flex-col md:flex-row justify-between bg-[#FCFFFF] rounded-lg  my-4">
            <div className="flex-1 text-[#A6AFB7] ">
              <div className='text-sm'>Due</div>
              <div className="text-black">{item.date}</div>
            </div>
            <div className="flex-1 text-[#A6AFB7] mt-[-2px] ml-6">
              <div>Treatment</div>
              <div className="text-black">{item.treatment}</div>
            </div>
            <div className="flex-1 text-[#A6AFB7] mt-[-2px] ml-6 ">
              <div>Dentist</div>
              <div className="text-black">{item.dentist}</div>
            </div>
            <div className="flex-1 text-[#A6AFB7] mt-[-2px] ml-6 ">
              <div>Price</div>
              <div className="font-semibold text-[#BB5A5A]">{item.price}</div>
            </div>
            <div className="text-black-400  border w-[85px] ml-4 h-[45px] bg-[#D5FAFF] rounded-[15px] flex items-center justify-center">
              <p className="text-center px-6 text-sm text-black">Invoice</p>
            </div>
          </div>
        ));
      default:
        return null;
    }
  };

  return (
    <div
      className={`p-4 mt-[26px] w-[0px] ${
        isSearchVisible ? 'w-[30px] h-[100px]' : 'w-[45%]  h-[260px] ' 
      } bg-gray-50 rounded-[15px]  overflow-y-scroll border border-[#00B5CE] transition-all duration-300 ease-in-out`}
    >
      <div className="flex  space-x-4 mb-4">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`px-4 py-2 rounded-[15px] text-sm transition-all duration-300 ${
              activeTab === tab.id
                ? 'bg-[#00B5CE] text-black shadow-md'
                : 'bg-white text-gray-500 hover:bg-gray-100'
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div>
        <h2 className="text-md font-bold mb-4">Root Canal Treatment</h2>
        <div>{renderContent()}</div>
      </div>
    </div>
  );
};

export default ClinicPage;
