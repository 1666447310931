import React from 'react';
import TopBar from '../../components/reception/topBar';
import Sidebar from '../../components/clinic/sideBar';
import Widgets from '../../components/superAdminDashBoard/dashboardWidget';
import Activity from '../../components/clinic/mainContent';

const ClinicAdminDashboard = () => {
  return (
    <div className="flex h-screen ">

      <Sidebar />


      <div className="flex-1 p-6">
        <div className=''>
        
        </div>

<div className='mt-[-5px] mb-4 flex justify-end'>
<button className="flex items-center  px-4 py-2 bg-cyan-500 w-[120px] text-white rounded-md">
      <span className="text-xl font-bold mr-2">+</span>
      <span className="text-sm font-medium">Add clinic</span>
    </button>
</div>
        
        <Widgets />
        

        <Activity />
      </div>
    </div>
  );
};

export default ClinicAdminDashboard;
