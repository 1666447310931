import React from 'react';

const Sidebar = () => {
  return (
    <div className="flex h-screen">
    
    
      <div className="w-48 py-32 bg-cyan-500">
        <div className="p-4 text-center">
          <span className="text-black text-lg  font-bold">Welcome to <br /> Dentalpro</span>
        </div>
       
      </div>
    </div>
  );
};

export default Sidebar;
