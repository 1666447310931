import React, { useState } from 'react';
import FileUpload from './fileUpload';

const DocumentsSection = ({ isSearchVisible }) => {
  const [showAddFileModal, setShowAddFileModal] = useState(false);

  const onAddFileClick = () => {
    setShowAddFileModal(true);
  };

  const closeFileUpload = () => {
    setShowAddFileModal(false);
  };

  return (
    <div className="relative">
      {/* Main Content */}
      <div
        className={`${
          isSearchVisible ? 'ml-[40px]' : 'ml-[200%] mt-[-280px]'
        } max-w-sm mx-auto w-[290px] border border-[#00B5CE] bg-white p-2 h-[260px] mt-[-280px] rounded-[15px] shadow-md ${
          showAddFileModal ? 'opacity-50 pointer-events-none' : ''
        } transition-all duration-300 ease-in-out`}
      >
        <div className="flex justify-between items-center mt-[16px]">
          <h3 className="text-[#00B5CE] font-semibold">Files/ Documents</h3>
          <button
            type="button"
            className="bg-cyan-500 h-[30px] w-[72px] text-white rounded-md mb-2"
            onClick={onAddFileClick}
          >
            Add File
          </button>
        </div>
        <div className="space-y-2">
          <div className="bg-white border border-gray-400 p-2 rounded-md shadow-sm">
            Check Up Result.pdf
          </div>
          <div className="bg-white border border-gray-400 p-2 rounded-md shadow-sm">
            Dental x-ray.pdf
          </div>
          <div className="bg-white border border-gray-400 p-2 rounded-md shadow-sm">
            Medical Prescriptions.pdf
          </div>
          <div className="bg-white border border-gray-400 p-2 rounded-md shadow-sm">
            Medical Prescriptions.pdf
          </div>
        </div>
      </div>

      {/* File Upload Modal */}
      {showAddFileModal && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-50 z-10"></div>
          <div className="fixed inset-0 z-20 flex items-center justify-center">
            <div className="relative bg-white p-6 rounded-[20px] ml-[800px] mt-[200px] shadow-md w-[270px]">
              <FileUpload closeFileUpload={closeFileUpload} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DocumentsSection;
