import React from 'react';

const PlusButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center justify-center w-6 h-6 ml-[132px]  bg-black rounded-full text-white text-2xl"
    >
       <p className='mt-[-7px]'>+</p>
    </button>
  );
};

export default PlusButton;
