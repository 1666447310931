import React from 'react';

const SearchClinics = () => {
  return (
    <div className=" p-4">
        <div className="text-black text-lg font-bold mb-6">request</div>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search Clinics"
            className="w-full p-2 rounded-md"
          />
        </div>
        <div className="space-y-4 w-52 ">
          {/* Example Sidebar Items */}
          <div className="flex items-center p-2 bg-gray-200 rounded-md">
            <span className="bg-gray-300 text-center text-gray-800 w-8 h-8 rounded-full">A</span>
            <span className="ml-4 text-gray-700">AGGGGGGh</span>
          </div>
          <div className="flex items-center p-2 bg-gray-200 rounded-md">
            <span className="bg-gray-300 text-center text-gray-800 w-8 h-8 rounded-full">A</span>
            <span className="ml-4 text-gray-700">AGGGGGGh</span>
          </div>
          <div className="flex items-center p-2 bg-gray-200 rounded-md">
            <span className="bg-gray-300 text-center text-gray-800 w-8 h-8 rounded-full">A</span>
            <span className="ml-4 text-gray-700">AGGGGGGh</span>
          </div>
        </div>
        
      </div>
      
  );
};

export default SearchClinics;
