import React from 'react';

const Widgets = () => {
  return (
    <div className="flex mb-8 gap-8">
      <div className="bg-[#D9D9D921] p-4 pt-2  h-20 w-[250px] rounded-[24px] ">
        <h3 className="w-[200px] h-[36px] text-[22.91px] leading-[36.27px] font-roboto font-normal text-[#697077]">Register patient</h3>
        <div className='flex '>
        <div className="text-2xl font-bold ">2.352M</div>
        <div className=" ml-12 mt-1">+11%</div>
        </div>
        
      </div>
      <div className='border-r-[1px] border-[#000000]  w-0px h-[81.5px]'></div>

      <div className="bg-[#D9D9D921] p-4 pt-2  h-20 w-[250px] rounded-[24px] ">
        <h3 className="w-[200px] h-[36px] text-[22.91px] leading-[36.27px] font-roboto font-normal text-[#697077]">Today Payment</h3>
        <div className='flex'>
        <div className="text-2xl font-bold">2.352M</div>
        <div className=" ml-8 mt-1">+11%</div>
        </div>
        
      </div>
      <div className='border-r-[1px] border-[#000000]  w-0px h-[81.5px]'></div>

      
      <div className="bg-[#D9D9D921] px-4 pt-4 h-20 w-48 w-[250px]  rounded-[24px] gap-2 ">
        <h3 className="text-[10px] text-black">MON</h3>
        <div className="text-3xl mt-[-10px] font-bold">08:54:58</div>
        <div className="text-[10px] mt-[-4px]">Let get moving</div>
      </div>
    </div>
  );
};

export default Widgets;
