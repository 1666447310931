import React from 'react';
import { BellIcon, CogIcon, UserCircleIcon } from '@heroicons/react/outline';
import UserProfile from './profile';
import { useNavigate } from 'react-router-dom';



const TopBar = () => {
  const navigate = useNavigate();
  const logout = () => {

    localStorage.removeItem('token');

    navigate('/');
    window.location.reload(true);
  };

  return (
    <div className="flex mb-12 items-center  justify-between border border-b-12 ">
      <div>
        <UserProfile />
      </div>
      <div className='flex gap-4 mb-4 justify-end'>
        <BellIcon className="h-6 w-6 mt-2 text-blue-500" />
        <CogIcon className="h-6 w-6 mt-2 text-blue-500" />
        <div className="flex items-center space-x-2">
          <button
            onClick={logout}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Logout
          </button>
          {/* <UserCircleIcon className="h-6 w-6 text-blue-500" />
          <span className="text-blue-500 font-medium">Welcome!</span> */}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
