import React, { useState } from 'react';
import AllAppointmentList from '../../components/clinic/allPatientAppointment';
import TopBar from '../../components/clinic/TopBar';
import Sidebar from '../../components/clinic/sideBar';
const AllAppointments = () => {
 

  return (
    <div className="relative min-h-screen overflow-x-hidden ">
        <div className='flex'>
        <Sidebar />
         
 
        </div>
        

     <AllAppointmentList />


      
    </div>
  );
};

export default AllAppointments;
