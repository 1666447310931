import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FilterOptions from './filterService';
import { server } from '../../../server';

const AllServiceList = () => {
  const [isServiceVisible, setIsServiceVisible] = useState(false);
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch services from the backend
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get(`${server}/api/v2/clinic-service/get-all-services`);
        setServices(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching services:', error);
        setIsLoading(false);
      }
    };

    fetchServices();
  }, []);

  const handleButtonClick = () => {
    setIsServiceVisible(true);
  };

  const closeForm = () => {
    setIsServiceVisible(false);
  };

  const handleRowClick = (service) => {
    console.log('Row clicked:', service);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full mt-[-462px] ml-[18%] overflow-x-hidden">
      <div className="flex justify-between">
        <p className="text-[#666E7D] whitespace-nowrap">
          Showing: <span className="text-black">{services.length} services</span>
        </p>
        <FilterOptions />
      </div>

      <div className="bg-white shadow sm:rounded-lg">
        <table className="w-[99%] divide-y divide-gray-100">
          <thead>
            <tr>
              <th className="px-6 w-[40%] py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">NAME OF THE SERVICE</th>
              <th className="py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">PRICE</th>
              <th className="py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Status</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {services.map((service, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(service)}
                className="cursor-pointer hover:bg-gray-300"
              >
                <td className="px-6 w-[40%] py-4 whitespace-nowrap text-sm text-black">{service.serviceName}</td>
                <td className="py-4 whitespace-nowrap text-sm text-black">{service.servicePrice}</td>
                <td className="py-4 whitespace-nowrap text-sm text-black">{service.serviceStatus}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllServiceList;
