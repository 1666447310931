import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import { server } from '../../server';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const fetchUserRequest = () => ({
  type: FETCH_USER_REQUEST,
});

export const fetchUserSuccess = (user) => ({
  type: FETCH_USER_SUCCESS,
  payload: user, // Simplified payload
});

export const fetchUserFailure = (error) => ({
  type: FETCH_USER_FAILURE,
  payload: error,
});

export const fetchUser = () => {
  return async (dispatch) => {
    dispatch(fetchUserRequest());
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.id;
        const response = await axios.get(`${server}/api/v2/employee/${userId}`);
        
        dispatch(fetchUserSuccess(response.data.data));
      } else {
        dispatch(fetchUserFailure('No token found'));
      }
    } catch (err) {
      dispatch(fetchUserFailure(err.response?.data?.message || 'Error fetching user data'));
    }
  };
};
