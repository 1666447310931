import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clinics: [],
  loading: false,
  error: null,
  activatedClinics: [],
  deactivatedClinics: [], 
};

const fetchAllClinicSlice = createSlice({
  name: 'clinics',
  initialState,
  reducers: {
    fetchClinicsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchClinicsSuccess: (state, action) => {
      state.loading = false;
      state.clinics = action.payload;
    },
    fetchClinicsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    activateClinicRequest: (state, action) => {
      },
      activateClinicSuccess: (state, action) => {
        state.activatedClinics.push(action.payload); // Add the activated clinic ID
        const index = state.clinics.findIndex(clinic => clinic._id === action.payload);
        if (index !== -1) {
          state.clinics[index].status = 'Activated'; 
        }
      },
      activateClinicFailure: (state, action) => {
        state.error = action.payload;
      },
      deactivateClinicRequest: (state, action) => {
      },
      deactivateClinicSuccess: (state, action) => {
        state.deactivatedClinics.push(action.payload);
        const index = state.clinics.findIndex(clinic => clinic._id === action.payload);
        if (index !== -1) {
          state.clinics[index].status = 'DeActivated'; 
        }
      },
      deactivateClinicFailure: (state, action) => {
        state.error = action.payload;
      },
    
  },
});

export const {
  fetchClinicsRequest,
  fetchClinicsSuccess,
  fetchClinicsFailure,
  activateClinicRequest, 
  activateClinicSuccess,
  activateClinicFailure,
    deactivateClinicRequest,
    deactivateClinicSuccess,
    deactivateClinicFailure,
} = fetchAllClinicSlice.actions;

export default fetchAllClinicSlice.reducer;
