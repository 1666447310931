import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from '../../../server';
import { jwtDecode } from 'jwt-decode';

const AddAppointmentForm = ({ onClose, patient }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const user = useSelector((state) => state.user.user);
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token);
  const employeeId = decodedToken.id;

  const [schedules, setSchedules] = useState([]);
  const [appointments, setAppointments] = useState([]); 
  const [formData, setFormData] = useState({
    fullName: patient?.firstName + ' ' + patient?.middleName + ' ' + patient?.lastName || '',
    patientId: patient?._id,
    phoneNumber: patient?.phoneNumber || '',
    clinicId: user.clinicId[0].clinic || '',
    appointmentDate: '',
    appointmentTime: '',
    employee: employeeId,
    status: "scheduled",
    scheduleId: '',
  });

  const [appointmentTimes, setAppointmentTimes] = useState([]);

  // Function to generate time slots
  const generateTimeSlots = (startTime, endTime) => {
    const slots = [];
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);
  
    while (start < end) {
      slots.push(start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }));
      start.setMinutes(start.getMinutes() + 30);
    }
  
    return slots;
  };

  // Fetch schedules by clinicId
  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        const response = await axios.get(`${server}/api/v2/schedule/clinic/${formData.clinicId}`);
        setSchedules(response.data.data);
      } catch (error) {
        console.error('Error fetching schedules:', error);
        toast.error('Failed to load schedules');
      }
    };
    if (formData.clinicId) {
      fetchSchedules();
    }
  }, [formData.clinicId]);

  // Fetch appointments by schedule ID
  useEffect(() => {
    const fetchAppointments = async (scheduleId) => {
      try {
        const response = await axios.get(`${server}/api/v2/appointments/appointment-schedule/${scheduleId}`);
        setAppointments(response.data.data);
      } catch (error) {
        console.error('Error fetching appointments:', error);
        toast.error('Failed to load appointments');
      }
    };

    if (formData.scheduleId) {
      fetchAppointments(formData.scheduleId);
    }
  }, [formData.scheduleId]);

  useEffect(() => {
    if (formData.scheduleId) {
      const selectedSchedule = schedules.find(schedule => schedule._id === formData.scheduleId);
      if (selectedSchedule) {
        const { scheduleTime, scheduleTimeEnd } = selectedSchedule;
        const times = generateTimeSlots(scheduleTime, scheduleTimeEnd);
        
        // Create a set of booked times for faster lookup
        const bookedTimes = new Set(appointments.map(appointment => appointment.appointmentTime));
        
        // Filter available times
        const availableTimes = times.filter(time => !bookedTimes.has(time));
        setAppointmentTimes(availableTimes);
      }
    }
  }, [formData.scheduleId, schedules, appointments]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'scheduleId') {
      const selectedSchedule = schedules.find(schedule => schedule._id === value);
      if (selectedSchedule) {
        const formattedDate = new Date(selectedSchedule.scheduleDate).toISOString().split('T')[0];
        setFormData({
          ...formData,
          [name]: value,
          appointmentDate: formattedDate,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      patientId: formData.patientId,
      clinicId: formData.clinicId,
      appointmentDate: formData.appointmentDate,
      appointmentTime: formData.appointmentTime,
      employee: formData.employee,
      status: formData.status,
      scheduleID: formData.scheduleId,
    };

    try {
      const response = await axios.post(
        `${server}/api/v2/appointments/create`,
        payload,
        { withCredentials: true }
      );
      console.log('Submitted Data:', response.data);
      toast.success("Appointment booked Successfully!");
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error.response?.data?.message || "Registration Failed");
    }
  };

  const handleClickOutside = (e) => {
    if (formRef.current && !formRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={formRef} className="bg-white rounded-2xl p-6 shadow-lg w-[600px] relative">
      <h2 className="text-lg font-semibold text-gray-700 mb-4">Add an appointment</h2>
      <button
        type="button"
        onClick={onClose}
        className="absolute top-2 font-bold text-[30px] right-4 text-gray-600 hover:text-gray-900 focus:outline-none"
      >
        &times;
      </button>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-cyan-500"
            placeholder="Full name"
          />
        </div>
        <div className="mb-4">
          <input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-cyan-500"
            placeholder="Phone number"
          />
        </div>

        <div className="mb-4">
          <select
            name="scheduleId"
            value={formData.scheduleId}
            onChange={handleChange}
            className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-cyan-500"
          >
            <option value="">Select Schedule</option>
            {schedules
              .filter((schedule) => {
                const scheduleDate = new Date(schedule.scheduleDate);
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return scheduleDate >= today;
              })
              .map((schedule) => (
                <option key={schedule._id} value={schedule._id}>
                  {schedule.doctorId?.firstName} {schedule.doctorId?.lastName} --{' '}
                  {new Date(schedule.scheduleDate).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  })}{' '}
                  start Time {schedule.scheduleTime} -- end Time {schedule.scheduleTimeEnd}
                </option>
              ))}
          </select>
        </div>

        <div className="flex justify-between mb-4">
          <div className="flex-1 mr-2">
            <input
              type="text"
              name="appointmentDate"
              placeholder='selected date'
              value={formData.appointmentDate ? new Date(formData.appointmentDate).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              }) : ''}
              readOnly
              className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-cyan-500"
            />
          </div>
          <div className="flex-1 ml-2">
            <select
              name="appointmentTime"
              value={formData.appointmentTime}
              onChange={handleChange}
              className="block w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-cyan-500"
            >
              <option value="">Select Appointment Time</option>
              {appointmentTimes.map((time, index) => (
                <option key={index} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-cyan-500 text-white rounded-lg hover:bg-cyan-600"
          >
            Add Appointment
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddAppointmentForm;
