import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setEmployeeData, submitEmployeeDataRequest, resetSubmissionStatus } from '../../slices/employee';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchUser } from '../../Redux/actions/user';
import { server } from '../../server';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const EmployeeForm = ({ onClose }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('employee');
  const navigate = useNavigate();
  const formRef = useRef(null);
  const user = useSelector((state) => state.user.user);
  const [availability, setAvailability] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [showFullTime, setShowFullTime] = useState(false);
  const [rows, setRows] = useState([{ day: '', start: '', finish: '' }]);
 
  const [formData, setFormData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    sex: '',
    dateOfBirth: '',
    phoneNumber: '',
    email: '',
    password: '',
    address: '',
    role: '',
    clinicId: user.clinicId[0].clinic || '', // Set clinicId from props
  });

  const getMaxDateOfBirth = () => {
    const today = new Date();
    const eighteenYearsAgo = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    // Return the date in the format 'YYYY-MM-DD'
    return eighteenYearsAgo.toISOString().split('T')[0];
  };

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleAvailabilityChange = (event) => {
    const value = event.target.value;
    setAvailability(value);
    setShowOptions(value === 'other'); // Show options only for 'reception'
    setShowFullTime(value === 'reception'); // Show full-time only for 'other'
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Form Data Collected: ", formData);
    // dispatch(resetSubmissionStatus()); // Reset the status before submission
    // dispatch(submitEmployeeDataRequest(employeeData.data));

    // Create the payload matching the backend structure
    const payload = {
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,
      sex: formData.sex,
      dateOfBirth: formData.dateOfBirth,
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      password: formData.password,
      address: formData.address,
      role: formData.role,
      clinicId: [
        {
          clinic: formData.clinicId, // Assuming formData.clinicId is a valid ObjectId
          status: true // or some logic to determine status
        }
      ],
    };

    try {
      const response = await axios.post(
        `${server}/api/v2/employee/create`,
        payload,
        { withCredentials: true });

      console.log('Submitted Data:', response.data);
      toast.success("Employee Registered Successfully!");

 
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error.response?.data?.message || "Registration Failed");
    }
  }

  const handleCancel = () => {
    onClose();
  };

  const handleClickOutside = (e) => {
    if (formRef.current && !formRef.current.contains(e.target)) {
      onClose();
    }
  };
  const handleRowChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };
  const addRow = () => {
    setRows([...rows, { day: '', start: '', finish: '' }]);
  };

  // Remove a row
  const removeRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };



    return (
      <div className="fixed top-0 right-0 w-full md:w-1/3 h-full bg-white shadow-lg p-6 overflow-y-auto">
        <ToastContainer />
        <h2 className="text-xl font-bold mb-4">Contact and ID Info</h2>
        <p className="w-full border-b-4 mb-4 border-gray"></p>
        <form onSubmit={handleSubmit}>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
            <div>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                className="w-full border-b-[1px] border-black focus:border-black outline-none py-2"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                name="middleName"
                placeholder="Middle Name"
                className="w-full  mb-2 border-b-[1px] border-black focus:border-black outline-none py-2"
                value={formData.middleName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div>
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              className="w-full  mb-4 border-b-[1px] border-black focus:border-black outline-none py-2"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
            <div>
              <label>Date Of Birth</label>
              <input
                type="date"
                name="dateOfBirth"
                placeholder="Month | Day | Year"
                className="w-full border-b-[1px] border-black focus:border-black outline-none py-2"
                value={formData.dateOfBirth}
                onChange={handleChange}
                max={getMaxDateOfBirth()} // Set max attribute to 18 yrs ago
              />
            </div>
            <div>
              <label>Gender</label>
              <input
                type="text"
                name="sex"
                placeholder="Gender"
                className="w-full  mb-2 border-b-[1px] border-black focus:border-black outline-none py-2"
                value={formData.sex}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block font-bold-200 mb-2">Email</label>
            <input
              type="email"
              name="email"
              placeholder="example@gmail.com"
              className="w-full border-b-[1px] border-black focus:border-black outline-none py-2"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold-200 mb-2">Phone</label>
            <input
              type="text"
              name="phoneNumber"
              placeholder="+251 95-----"
              className="w-full border-b-[1px] border-black focus:border-black outline-none py-2"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold-200 mb-2">Address Line 1</label>
            <input
              type="text"
              name="address"
              placeholder="Addis Ababa"
              className="w-full border-b-[1px] border-black focus:border-black outline-none py-2"
              value={formData.address}
              onChange={handleChange}
            />
          </div>

          <div className="mb-4">
            <label className="block font-bold-200 mb-2">Password</label>
            <input
              type="password"
              name="password"
              placeholder="********"
              className="w-full border-b-[1px] border-black focus:border-black outline-none py-2"
              value={formData.password}
              onChange={handleChange}
            />
          </div>

          <h3 className="text-lg font-bold text-gray-700 mb-2">Employment Status</h3>
          <div className="mb-4">
            <div className="flex flex-col ml-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="status"
                  value="employee"
                  checked={formData.status === 'employee'}
                  onChange={handleChange}
                  className="mr-2"
                />
                Employee
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="status"
                  value="terminated"
                  checked={formData.status === 'terminated'}
                  onChange={handleChange}
                  className="mr-2"
                />
                Terminated
              </label>
            </div>
          </div>

          <h3 className="text-lg mb-2">Assign Role</h3>
          <div className="mb-4">
            <select
              value={formData.role}
              name="role"
              onChange={handleChange}
              className="w-[130px] border-b-2 border-gray-300 focus:border-black outline-none py-2 bg-transparent"
            >
              <option value="">Select Role</option>
              <option value="Doctor">Doctor</option>
              <option value="Nurse">Nurse</option>
              <option value="Reception">Receptionist</option>
            </select>
          </div>
          <div className="flex items-start mb-6">
            <div className="relative">
              <label className="block mb-2">Availability</label>
              <select
                value={availability}
                onChange={handleAvailabilityChange}
                className="w-30 border-b-2 border-gray-300 focus:border-black outline-none py-2 bg-transparent"
              >
                <option value="reception">Reception</option>
                <option value="other">Other</option>
              </select>
              {showOptions && (
              <div className="relative left-[80px] w-full top-[2px] bg-white shadow-md border whitespace-nowrap  rounded p-4">
                <div className="grid grid-cols-4 gap-8 font-bold">
                  <div className=" text-sm">Day</div>
                  <div className="text-sm">Start Time</div>
                  <div className="text-sm">End Time</div>
                  <div className="text-sm">Actions</div>
                </div>
                {rows.map((row, index) => (
                  <div key={index} className="grid grid-cols-4 whitespace-nowrap gap-4 mt-2">
                    <div className="col-span-1">
                      <select
                        value="day"
                        onChange={(e) => handleRowChange(index, 'day', e.target.value)}
                        className="border border-gray-300 h-[21px] rounded p-2 w-full"
                      >
                        <option value="">Day</option>
                        <option className="text-sm" value="Monday">Monday</option>
                        <option className="text-sm" value="Tuesday">Tuesday</option>
                        <option className="text-sm" value="Wednesday">Wednesday</option>
                        <option className="text-sm" value="Thursday">Thursday</option>
                        <option className="text-sm" value="Friday">Friday</option>
                        <option className="text-sm" value="Saturday">Saturday</option>
                        <option className="text-sm" value="Sunday">Sunday</option>
                      </select>
                    </div>
                    <div className="col-span-1">
                      <input
                        type="time"
                        value={row.start}
                        onChange={(e) => handleRowChange(index, 'start', e.target.value)}
                        className="border border-gray-300 h-[21px] rounded p-1 w-full"
                      />
                    </div>
                    <div className="col-span-1">
                      <input
                        type="time"
                        value={row.finish}
                        onChange={(e) => handleRowChange(index, 'finish', e.target.value)}
                        className="border border-gray-300 h-[21px] rounded p-1 w-full"
                      />
                    </div>
                    <div className="col-span-1 flex items-center">
                      <button
                        type="button"
                        onClick={() => removeRow(index)}
                        className="bg-red-500 text-white  text-sm px-2 rounded hover:text-red-700"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addRow}
                  className="bg-green-500 text-white text-sm mt-4 px-2 rounded hover:bg-green-700"
                >
                  Add Row
                </button>
              </div>
            )}
              {showFullTime && (
                <div className="ml-[80px]  top-[2px] bg-white shadow-md border rounded p-2">
                  <div className="text-center ">Full-time</div>
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="border border-gray-300 py-2 px-4 rounded hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-cyan-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  };

  export default EmployeeForm;
