// components/Layout.js
import React from 'react';
import TopBar from '../superAdminDashBoard/topBar'; 

const Layout = ({ children }) => {
  return (
    <div className="inset-0">
      <TopBar />
      <div className="mt-[-50px]">
        {children}
      </div>
    </div>
  );
};

export default Layout;
