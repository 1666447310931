import React, { useState } from 'react';
import ProfileCard from './patientCard';
import NotesSection from './noteSession';
import TreatmentHistory from './treatment';
import DocumentsSection from './docUment';
import FileUpload from './fileUpload';

const PatientProfile = ({ patient, isSearchVisible }) => {
  const [isFileUploadVisible, setIsFileUploadVisible] = useState(false);

  const handleAddFileClick = () => {
    setIsFileUploadVisible(true);
  };

  return (
    <div className={`h-screen overflow-x-auto ${isSearchVisible ? 'relative ml-0 sm:ml-0 md:ml-2 lg:ml-2 w-full' : 'mt-[3px]'}`}>
    {/* Ensure the content takes a width larger than the screen */}
    <div className="min-w-[1200px]"> {/* Set the minimum width to trigger horizontal scrolling */}
      <ProfileCard patient={patient} />
      <NotesSection />
      <TreatmentHistory />

      <div className="flex mt-4">
        {!isFileUploadVisible ? (
          <DocumentsSection onAddFileClick={handleAddFileClick} />
        ) : (
          <FileUpload />
        )}
      </div>
    </div>
  </div>
  );
};


export default PatientProfile;
