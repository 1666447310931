import React, { useState } from 'react';
import TopBar from '../../components/reception/patient/topBar';
import Sidebar from '../../components/reception/sideBar';
import AddPatientForm from '../../components/reception/patient/addpatientForm';
import SearchPatient from '../../components/reception/patient/searchPatient';
import AddAppointment from '../../components/reception/patient/addAppointment';
import PatientProfiles from '../../components/reception/patient/patientProfileReception';
import AllAppointments from '../../components/clinic/patientDetail/appointment';

const PatientsDetail = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isAppointmentVisible, setIsAppointmentVisible] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [activeTab, setActiveTab] = useState('about');
  const [isSearchVisible, setIsSearchVisible] = useState(true);

  const handlePatientClick = (patient) => {
    setSelectedPatient(patient);
    setIsFormVisible(false);
    setActiveTab('about');
  };

  const handlePlusClick = () => {
    setSelectedPatient(null);
    setIsFormVisible(true);
    setIsSearchVisible(true);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const closeForm = () => {
    setIsFormVisible(false);
    setIsAppointmentVisible(false);
  };

  const toggleSearchVisibility = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  return (
    <div className="relative min-h-screen overflow-x-hidden overflow-y-hidden">
      <div className="flex">
        {/* Sidebar stays visible */}
        <Sidebar />

        <div className="flex-grow flex flex-col">
          {/* Top Bar with Hamburger Button */}
          <div className="flex items-center justify-between mt-8 px-4">
            {/* Top bar content can be re-enabled */}
          </div>

          {/* Main Content */}
          <div
            className={`flex-grow h-screen ${
              isSearchVisible && selectedPatient
                ? 'flex flex-row'
                : 'flex flex-col'
            } overflow-x-hidden`}
          >
            {/* SearchPatient component */}
            {isSearchVisible && (
              <div className="flex-grow w-full md:w-1/3 lg:w-1/4">
                <SearchPatient
                  onPlusClick={handlePlusClick}
                  onEmployeeClick={handlePatientClick}
                />
              </div>
            )}

            {/* Patient Profile Area */}
            {selectedPatient && (
              <div className="flex-grow w-full md:w-2/3 lg:w-[1000px]">
                {/* Tab Buttons */}
                <div className="flex space-x-2 mb-4 mt-[-12px] ml-[20px]">
                  <button
                    className={`px-2 py-2 text-sm ${
                      activeTab === 'about'
                        ? 'text-black font-bold underline'
                        : 'text-gray-600'
                    }`}
                    onClick={() => handleTabClick('about')}
                  >
                    About
                  </button>
                  <button
                    className={`px-2 py-2 text-sm ${
                      activeTab === 'appointment'
                        ? 'text-black font-bold underline'
                        : 'text-gray-600'
                    }`}
                    onClick={() => handleTabClick('appointment')}
                  >
                    Appointment
                  </button>
                </div>

                {/* Patient Profile or Appointments */}
                <div className="whitespace-nowrap overflow-x-scroll">
                  {activeTab === 'about' && (
                    <div className=" ">
                      <PatientProfiles
                        patient={selectedPatient}
                        isSearchVisible={isSearchVisible}
                      />
                    </div>
                  )}
                  {activeTab === 'appointment' && (
                    <div className="whitespace-nowrap ">
                      <div className="mt-[2px]">
                        <button
                          type="submit"
                          className="px-4 ml-[57.2%] py-2 bg-cyan-500 text-white rounded-md"
                          onClick={() => setIsAppointmentVisible(true)}
                        >
                          + Add Appointment
                        </button>
                      </div>
                      <AllAppointments isSearchVisible={isSearchVisible} />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Add Patient Form Modal */}
      {isFormVisible && (
        <>
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-10"></div>
          <div className="fixed inset-0 z-20 flex justify-center items-center">
            <AddPatientForm onClose={closeForm} />
          </div>
        </>
      )}

      {/* Add Appointment Modal */}
      {isAppointmentVisible && (
        <>
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-10"></div>
          <div className="fixed inset-0 z-20 flex justify-center items-center">
            <AddAppointment onClose={closeForm} patient={selectedPatient} />
          </div>
        </>
      )}
    </div>
  );
};

export default PatientsDetail;
