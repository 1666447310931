import React from 'react';

const NotesSection = ({ isSearchVisible }) => {
  return (
    <div
      className={`ml-[626px]${
        isSearchVisible ? 'ml-[40px]' : 'ml-[626px]'
      } max-w-md w-[300px] mt-[-200px] h-[200px] mx-auto bg-[#FAFAFA] border border-[#00B5CE] rounded-[15px] p-2 transition-all duration-300 ease-in-out`}
    >
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-gray-400 font-semibold">Notes</h3>
        <button className="text-[#00B5CE] font-semibold">See all</button>
      </div>
      <div className="bg-white">
        <ul className="space-y-2">
          <li className="text-gray-800">• Some stuff happened</li>
          <li className="text-gray-800">• Some stuff happened and yea</li>
        </ul>
        <div className="my-4 flex items-center justify-between text-gray-400">
          <span className="flex-grow border-t"></span>
          <span className="mx-4 text-[10px] text-black">Mon 14 / 2000</span>
          <span className="flex-grow border-t"></span>
        </div>
        <ul>
          <li className="mt-[-16px] text-gray-800">• Some stuff happened so much stuff</li>
        </ul>
        <button className="bg-cyan-500 text-white px-4 py-1 ml-[220px] mt-2 rounded-md">
          <p>Edit</p>
        </button>
      </div>
    </div>
  );
};

export default NotesSection;
