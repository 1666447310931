import React from 'react';

const TopBar = () => {
  return (
    <div className="relative">
      


      <div className="flex items-center  h-10 w-[990px] bg-gray-300 p-4">
     

        <div className="w-[70px] p-2 bg-cyan-500 ml-[600px] text-black text-center font-bold">
          LOGIN
        </div>
      </div>
    </div>
  );
};

export default TopBar;
