import React from 'react';

const Widgets = () => {
  return (
    <div className="flex mb-2 gap-4">
      <div className="bg-[#D9D9D921] p-4 pt-2 h-20 rounded-[24px]">
        <h3 className="w-[200px] h-[36px] text-[22.91px] leading-[36.27px] font-roboto font-normal text-[#697077]">Register patient</h3>
        <div className='flex '>
        <div className="text-2xl font-bold ">2.352M</div>
        <div className=" ml-8 mt-1 text-400">+11%</div>
        </div>
        
      </div>
      <div className='border-r-[1px] border-[#000000]  w-0px h-[81.5px]'></div>

      <div className="bg-[#D9D9D921] p-4 pt-2 h-20 rounded-[24px]">
        <h3 className="w-[210px] h-[36px] whitespace-nowrap text-[22.91px] leading-[36.27px] font-roboto font-normal text-[#697077]">Collected Payment</h3>
        <div className='flex'>
        <div className="text-2xl font-bold">2.352M</div>
        <div className=" ml-8 mt-1">+11%</div>
        </div>
        
      </div>
      <div className='border-r-[1px] border-[#000000]  w-0px h-[81.5px]'></div>

      <div className="bg-[#D9D9D921] p-4  pt-2 h-20 rounded-[24px]">
        
        <h3 className="w-[182px] h-[36px] whitespace-nowrap text-[22.91px] leading-[36.27px] font-roboto font-normal text-[#697077]">Worker</h3>
        <div className="text-2xl font-bold">150</div>
      </div>
      <div className='border-r-[1px] border-[#000000]  w-0px h-[81.5px]'></div>

      <div className="bg-[#D9D9D921] p-4 pt-2 h-20 rounded-[24px]">
        <h3 className="w-[172px] h-[36px] text-[14px] whitespace-nowrap  leading-[36.27px] font-roboto font-normal text-[#697077]">MON</h3>
        <div className="text-[14px] ">you got 3 days left </div>
        
      </div>
    </div>
  );
};

export default Widgets;
