import React from 'react';
import { BellIcon, CogIcon, UserCircleIcon } from '@heroicons/react/outline';
import SearchBar from './searchBar';

const TopBar = () => {
  return (
    <div className="flex  mb-2  items-center space-x-[250px]">
   
       <SearchBar />
     
   
      
      {/* <div className='flex '>
      <div className='flex gap-4 ml-[2px] justify-end'>
     
     <BellIcon className="h-6 w-6 text-blue-500" />
     <CogIcon className="h-6 w-6 text-blue-500" />
     <div className="flex items-center space-x-2">
       <UserCircleIcon className="h-6 w-6 text-blue-500" />
       <span className="text-blue-500 font-medium">Welcome!</span>
     </div>
   </div>
      </div> */}
     
    </div>
  );
};

export default TopBar;
