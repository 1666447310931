import React, { useState } from 'react';
import AllServiceList from '../../components/clinic/service/listOfServices';
import TopBar from '../../components/clinic/service/topBar';
import Sidebar from '../../components/clinic/sideBar';
import ServiceForm from '../../components/clinic/service/serviceForm'; 

const AllServices = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleAddServiceClick = () => {
    setIsFormVisible(true);
  };

  const handleCloseForm = () => {
    setIsFormVisible(false);
  };

  return (
    <div className="relative min-h-screen overflow-hidden ">
      <div className="flex">
        <Sidebar />
        <TopBar />
        <div>
          <button
            type="submit"
            className="px-2 py-2 ml-[468px] bg-cyan-500 h-10 w-[182px] mt-[10%] text-white rounded-md"
            onClick={handleAddServiceClick} 
          >
            + Add Service
          </button>
        </div>
      </div>

      <div className="flex">
        <AllServiceList />
        
        {isFormVisible && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-10 flex justify-center items-center">
            <div className="bg-white p-8 rounded-lg shadow-md relative">
              <ServiceForm />
              <button
                className="absolute mt-[-360px] font-bold  text-[25px] right-[16px] text-gray-600 hover:text-gray-900 focus:outline-none"
                onClick={handleCloseForm}
              >
                x
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllServices;
