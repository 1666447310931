import React, {useState, useEffect} from 'react';
import PlusButton from './plusButton';
import { useDispatch, useSelector } from 'react-redux';
import { server } from '../../../server';
import { fetchUser } from '../../../Redux/actions/user';
import axios from 'axios';
 
// Function to get all patients by clinicId
const fetchPatients = async (clinicId) => {
  try {
    const response = await axios.get(`${server}/api/v2/patients/get-all`, {
      params: { clinicId } // Attach clinicId as query parameter
    });
    return response.data; // Return the data from the response
  } catch (error) {
    console.error('Error fetching patients:', error);
    throw error; // Rethrow the error to handle it in the component
  }
};


const SearchPatient = ({ onPlusClick, onEmployeeClick }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [patients, setPatients] = useState([]);
  const [error, setError] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    const loadPatients = async () => {
      if (user && user.clinicId && user.clinicId.length > 0) {
        const clinicId = user.clinicId[0]?.clinic;

        if (clinicId) {
          try {
            const data = await fetchPatients(clinicId);
            setPatients(data);
          } catch (error) {
            setError('Failed to fetch patients');
            console.error('Fetch Error:', error);
          }
        } else {
          setError('Clinic ID is not valid');
        }
      } else {
        setError('No valid clinic ID found');
      }
    };

    if (user && user.clinicId && user.clinicId.length > 0) {
      loadPatients();
    }
  }, [user]);

  const handlePatientClick = (patient) => {
    setSelectedPatient(patient); 
  };


  return (
    <div className="p-4 mt-[-30px]">
      <div className="flex mb-2">
      <div className="flex mt-12">
        <h2 className="ml-2 font-bold">Patient</h2>
      
        <button
      onClick={onPlusClick}
      className="flex items-center justify-center w-6 h-6 ml-[132px]  bg-black rounded-full text-white text-2xl"
    >
       <p className='mt-[-7px]'>+</p>
    </button>
    </div>
      </div>
      <input
  type="text"
  placeholder="Search Patient"
  className="w-full py-2 mb-2 rounded-lg px-4 text-sm border border-gray-300 sm:py-1 sm:px-2 md:py-2 mb-[md:px-4"
/>
<div className="space-y-2 w-[210px]">
        {patients.map((patient) => (
          <div
            key={patient.id}
            className="flex items-center  bg-gray-200 h-6 rounded-md cursor-pointer"
            onClick={() => onEmployeeClick(patient)}
          >
            <span className="bg-gray-300 text-center text-gray-800 w-6 h-6 rounded-full">
        {patient.firstName ? patient.firstName[0] : ''}
      </span>
      <span className="ml-4 text-gray-700 text-sm">
      {`${patient.firstName} ${patient.middleName ? patient.middleName + ' ' : ''}${patient.lastName}`}
      </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchPatient;
