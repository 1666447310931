import React, { useState } from 'react';
import FilterOptions from '../reschedule/filter'; 
import DateSelector from '../reschedule/dateSelector';
import AddAppointment from '../../../components/reception/patient/addAppointment';
const appointments = [
  {
    time: "18:15 - 19:00",
    patient: "Dianne Russell",
    test: "Upper Abdomen General",
    doctor: "Kristin",
    location: "4517 Washington Ave.",
  },
  {
    time: "17:45 - 18:00",
    patient: "Bessie Cooper",
    test: "Gynecologic Disorders",
    doctor: "Kristin",
    location: "2715 Ash Dr. San Jose",
  },
  {
    time: "17:45 - 18:00",
    patient: "Bessie Cooper",
    test: "Gynecologic Disorders",
    doctor: "Kristin",
    location: "2715 Ash Dr. San Jose",
  },
 
];

const AppointmentList = () => {
  const [isAppointmentVisible, setIsAppointmentVisible] = useState(false);

    const handleButtonClick = () => {
        setIsAppointmentVisible(true); 
      };
      const closeForm = () => {
    
        setIsAppointmentVisible(false);
      };
    
  return (
    <div className="w-full ">
      <div className="flex justify-between items-center mb-4">
        
      </div>
      <div className='flex'>
        <h2 className="text-2xl font-semibold">Appointments</h2>
      <DateSelector />
      </div>
      <div className='flex justify-between'>
        <p className='text-[#666E7D] whitespace-nowrap'>
          Showing : <span className='text-black '>10 appointments</span>
        </p>
        <FilterOptions />
      </div>
   
      
      <div className="bg-white shadow  sm:rounded-lg">
        <table className="w-[98.87%] divide-y divide-gray-100">
          <thead className="">
            <tr >
              <th className="px-3 py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Time</th>
              <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Patient</th>
              <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Test</th>
              <th className="px-6 py-3 bg-gray-100  text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Doctor</th>
              <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Location</th>
              <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {appointments.map((appointment, index) => (
              <tr key={index}>
                <td className="px-3 py-4 whitespace-nowrap text-sm text-black">{appointment.time}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-black">{appointment.patient}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-black">{appointment.test}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-black">{appointment.doctor}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-black">{appointment.location}</td>
                <td className="px-6 w-[90%]  py-4 whitespace-nowrap  text-sm font-medium">
                  <button 
                  type='submit'
                  className="text-white border p-[4px] rounded-lg bg-cyan-500 hover:text-blue-700"
                  onClick={handleButtonClick}
                  >
                    Reschedule</button>
                  <button className="ml-4 text-white  border  p-[4px] rounded-lg bg-cyan-500 hover:text-red-700">Cancel</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isAppointmentVisible && (
        <>
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 z-10"></div>
          <div className="fixed inset-0 z-20 flex justify-center items-center">
            <AddAppointment onClose={closeForm} />
          </div>
        </>
      )}
      </div>
    </div>
  );
};

export default AppointmentList;
