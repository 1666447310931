import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FilterOptions from './filter';
import axios from 'axios';
import { server } from '../../server';
import SmallLoading from '../smallLoading'; // Import your loading animation component
 

const AllAppointmentList = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const user = useSelector((state) => state.user.user);
  const id = user?.clinicId[0]?.clinic || '';

  useEffect(() => {
    const fetchAppointments = async () => {
      if (!id) {
        console.log('Clinic ID not available yet');
        return; // Return early if no clinic ID is available
      }

      try {
        const response = await axios.get(`${server}/api/v2/appointments/clinic/${id}`);
        const sortedAppointments = response.data.data.sort((a, b) =>
          new Date(b.appointmentDate) - new Date(a.appointmentDate)
        );
        setAppointments(sortedAppointments);
        setLoading(false); // Set loading to false after data is fetched
        console.log(sortedAppointments);
      } catch (error) {
        console.error("Error fetching appointments:", error);
        setLoading(false); // Set loading to false if there's an error
      }
    };

    fetchAppointments();
  }, [id]);

  const handleRowClick = (appointment) => {
    console.log('Row clicked:', appointment);
  };

  return (
    <div className="w-full mt-4 ml-[210px] mt-[-500px] overflow-x-hidden">
      {/* Heading and Filter Options */}
      <div className='flex justify-between'>
 
        <p className='text-[#666E7D]'>
          Showing : <span className='text-black '>10 appointments</span></p>
 
        <p className='text-gray-400'>
          Showing <span className='text-black'>{appointments.length} appointments</span>
   
        </p>
        <FilterOptions />
      </div>
 
   
      <div className="bg-white shadow sm:rounded-lg overflow-y-scroll ">
         {loading ? (
          <div className="flex justify-center items-center h-64"> {/* You can adjust height as needed */}
            <SmallLoading /> {/* Loading animation */}
          </div>
        ) : (
        <table className="w-[83%] divide-y divide-gray-100">
          <thead className="">
            <tr>
              <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Time</th>
              <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Patient</th>
              <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Clinics</th>
              <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Test</th>
              <th className="px-6 py-3 bg-gray-100 text-left text-xs font-medium text-[#666E7D] uppercase tracking-wider">Location</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {appointments.map((appointment, index) => (
              <tr key={index}
              onClick={() => handleRowClick(appointment)}
              className='cursor-pointer hover:bg-gray-300'
              >
                <td className="px-6 py-4 whitespace-nowrap text-sm text-black">{new Date(appointment.appointmentDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: '2-digit',
                    })}</td>
                <td className=" px-6 py-4 whitespace-nowrap text-sm text-black">{appointment.appointmentTime || 'N/A'}</td>
                <td className=" px-6 py-4 whitespace-nowrap text-sm text-black">
                    {appointment.patientId ? `${appointment.patientId.firstName} ${appointment.patientId.middleName} ${appointment.patientId.lastName}  ` : 'N/A'}

                  </td>
                <td className=" px-6 py-4 whitespace-nowrap text-sm text-black">{appointment.clinic || 'N/A'}</td>
                <td className="px-6  py-4 whitespace-nowrap text-sm text-black">{appointment.test || 'N/A'}</td>
                <td className="px-6 w-[40%] py-4 whitespace-nowrap text-sm text-black">{appointment.location || 'N/A'}</td>
               </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );

};

export default AllAppointmentList;
