import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { fetchUser } from '../../../Redux/actions/user';
import { toast } from 'react-toastify';
import { server } from '../../../server';

const ServiceForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const user = useSelector((state) => state.user.user);
  const [formData, setFormData] = useState({
    serviceName: '',
    servicePrice: '',
    serviceStatus: "active",
    clinicId: user.clinicId[0].clinic || '', // Set clinicId from props
  });
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  const handleChange = (e) => {
    setFormData({
      ...formData,

      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

  

    // Create the payload matching the backend structure
    const payload = {
      serviceName: formData.serviceName,
      servicePrice: formData.servicePrice,
      serviceStatus: formData.serviceStatus,
      clinicId: formData.clinicId // Assuming clinicId is a single value, not an array
    };
    console.log("inputed service data", payload);

    try {
      const response = await axios.post(
        `${server}/api/v2/clinic-service/create-service`,
        payload,
        { withCredentials: true }
      );
      console.log('Submitted Data:', response.data);
      toast.success("Servcie setted Successfully!");

      // Perform any further actions like navigating to another page
      // navigate('/success'); // Example: navigate to a success page after form submission

     // onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error(error.response?.data?.message || "Registration Failed");
    }
  };
  return (
    <div ref={formRef} className="max-w-2xl mx-auto p-8 rounded-lg">
         
      <h2 className="text-xl font-bold mb-4">Service Data</h2>
      <form onSubmit={handleSubmit}>
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Service name"
            name="serviceName"
            value={formData.serviceName}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          <input
            type="text"
            placeholder="Set the price"
            name="servicePrice"
            value={formData.servicePrice}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="flex justify-end mt-8">
          <button 
          onClick={handleSubmit}
          className="bg-cyan-500 text-white px-4 py-2 rounded-md">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default ServiceForm;
