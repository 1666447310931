import React from 'react';
import BarChart from './smallComponents/barChart';
import PieChart from './smallComponents/pieChart';
const Activity = () => {
  return (
    <div className="p-6 ">
        <div className='flex'>
        <PieChart/>
        <BarChart/>
       
        </div>
    
        
    </div>
  );
};

export default Activity;
