import React from 'react';
import Sidebar from '../../components/superAdminDashBoard/sideBar';
import AdminSignUpForm  from '../../components/auth/superAdminSignUP';
const superAdmin
 = () => {
  return (
    <div className="flex h-screen">

    
      <AdminSignUpForm />
     

    </div>
  );
};

export default superAdmin
;
