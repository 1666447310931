import { React, useState, useEffect } from 'react';
import Sidebar from '../../components/auth/signInSideBar.jsx';
import TopBar from '../../components/auth/loginTopBar.jsx';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../server.js';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/loadin.jsx'; // Import the Loader component
import { fetchUser } from '../../Redux/actions/user.js';

const SignInForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, error } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // UseEffect to handle redirection based on role after user data is fetched
  useEffect(() => {
    if (user && user.role) {
      if (user.role === 'Doctor') {
        navigate('/clinic');
      } else if (user.role === 'Reception') {
        navigate('/reception');
      }
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    try {
      const response = await axios.post(
        `${server}/api/v2/employee/login`,
        { email, password },
        { withCredentials: true }
      );

      const token = response.data.token;
      if (token) {
        localStorage.setItem('token', token);
      }
      
      toast.success("Login Success");

      // Dispatch fetchUser to load user data into Redux after successful login
      dispatch(fetchUser());

    } catch (err) {
      toast.error(err.response?.data?.message || "Login Failed");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Render the loader component when loading is true */}
      {loading && <Loader loading={loading} />}

      <Sidebar />
      <div className='flex flex-col'>
        <TopBar />
        <div className="mt-20 flex flex-col justify-center items-center">
          <div className="w-full max-w-md p-8">
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-3xl font-bold text-gray-800">Dashboard LOGIN</h2>
            </div>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <input
                  type="email"
                  placeholder="email"
                  name="email"
                  autoComplete='email'
                  value={email}
                  onChange={(e)=> setEmail(e.target.value)}
                  className="w-full p-3 border h-10  border-gray-300 rounded-xl"
                />
              </div>
              <div>
                <input
                  type="password"
                  placeholder="Password"
                  name='password'
                  value={password}
                  onChange={(e)=> setPassword(e.target.value)}
                  autoComplete='current-password'
                  className="w-full p-3 border h-10  border-gray-300 rounded-xl"
                />
              </div>
              <div className="flex items-center justify-between text-sm">
                <label className="flex items-center">
                  <input type="checkbox" className="mr-2" />
                  Keep me remembered?
                </label>
                <a href="#" className=" px-4 text-cyan-500">
                  Forgot password?
                </a>
              </div>
              <button
                type="submit"
                className="w-full bg-cyan-500 text-black p-3 rounded-md font-semibold"
                disabled={loading} // Disable the button when loading
              >
                {loading ? 'Logging in...' : 'Login'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInForm;
